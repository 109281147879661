<template>
    <div class="consulting-expert-base">
        <sn-bread :itemName="itemName" :breadlist="breadlist"></sn-bread>
        <router-view class="consulting-expert-view"></router-view>
    </div>
</template>
<script>
export default {
    name: "consulting_expert",
    data() {
        return {
            itemName: '咨询专家',
            breadlist: [
                {
                    path: '',
                    name: '专家咨询'
                }
            ],
        }
    }
}
</script>
<style scoped>
.consulting-expert-base{
    width: 100%;
    height: 100%;
    /* background: #fff !important; */
}
.consulting-expert-view{
    width: 100%;
    height: calc(100% - 45px);
}
</style>